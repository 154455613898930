export default [
  {
    path: "",
    name: "lodgings",
    component: () => import("../views/lodgings"),
  },

  {
    path: "/lodgings",
    name: "lodgings",
    component: () => import("../views/lodgings"),
  },

  {
    path: "/lodgings/:id/booking",
    name: "booking",
    component: () => import("../views/booking"),
  },
];
