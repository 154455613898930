import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BootstrapVue from "bootstrap-vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Multiselect from "vue-multiselect";
import Scrollspy from "vue2-scrollspy";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { setInteractionMode } from "vee-validate";
import "@/design/index.scss";

Vue.use(VueRouter);
Vue.use(BootstrapVue);
const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);
Vue.use(Scrollspy);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("DatePicker", DatePicker);
Vue.component("Multiselect", Multiselect);

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "This field is required",
});

extend("email", {
  ...email,
  message: "Invalid email",
});

extend("min", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
  message: (fieldName, placeholders) => {
    return `must be at least ${placeholders.length} characters long`;
  },
});

extend("minimum", {
  validate(value, { target }) {
    return parseInt(value) >= parseInt(target);
  },
  params: ["target"],
  message: (fieldName, placeholders) => {
    return `cannot be less than ${placeholders.target}`;
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
